import { alpha, Card, Stack, Typography, useTheme } from "@mui/material"

const StatCard = ({ icon, name, value }) => {
  const theme = useTheme()
  return (
    <Card
      elevation={0}
      sx={{
        bgcolor: alpha(theme.palette.primary.main, 0.1),
        px: 2.5,
        py: 3,
        color: theme.palette.primary.main,
      }}
    >
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon}
          <Typography color={theme.palette.text.primary}>{name}</Typography>
        </Stack>
        <Typography variant="h6">{value}</Typography>
      </Stack>
    </Card>
  )
}

export default StatCard
