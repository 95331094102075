import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material"
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"
import ColorModeToggle from "../../components/common/ColorModeToggle"
import { Link } from "react-router-dom"
import routes from "../../config/routes"
import { testimonials } from "../../pages/landing/Landing"

const AuthSidePic = ({ loginBtn }) => {
  return (
    <Grid
      item
      xs={6}
      sx={{
        background: `url('/assets/images/${
          loginBtn ? "register" : "login"
        }_img.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <Box sx={{ background: "rgba(0,0,0,0.3)" }}>
        <Container maxWidth="sm">
          <Stack
            direction={"column-reverse"}
            justifyContent="space-between"
            alignItems="center"
            pt={3}
            pb={6}
            sx={{ minHeight: "100vh" }}
          >
            <div>
              <Typography variant="body1" color="white" mb={1}>
                {testimonials[0].msg}
              </Typography>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                spacing={2}
                alignItems="center"
              >
                <div>
                  <Typography variant="h6" color="white">
                    {testimonials[0].name}
                  </Typography>
                  <Typography color="primary">
                    {testimonials[0].desc}
                  </Typography>
                </div>
                <Rating value={5} sx={{ color: "#3ABF7C" }} />
              </Stack>
            </div>
            {loginBtn && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography color="white">Already Have an account?</Typography>
                <Link to={routes.LOGIN}>
                  <Button variant="contained">
                    Log In <ArrowForwardRoundedIcon fontSize="small" />
                  </Button>
                </Link>

                <ColorModeToggle />
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>
    </Grid>
  )
}

export default AuthSidePic
