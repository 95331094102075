const env = {
  // BASE_API_URL: "http://localhost:7400/api/v1",

  BASE_API_URL: "https://api.shipease.io/api/v1",
  RECAPTCHA_KEY: "6LfBSg4kAAAAAFFM4XkJ0SRzvBaWFk-GeTTL3WQn",
  GOOGLE_API_KEY: "AIzaSyC-EleT52YJApeYtV3nU-7QudcJFuPO98w",
  downloadConfig: {
    responseType: "blob",
  },
};

export default env;
