import { Stack, Typography } from "@mui/material"

const PageContainer = ({ title, end, children }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
        {end}
      </Stack>

      {children}
    </>
  )
}

export default PageContainer
